import * as React from 'react'
import axios from 'axios'
import partial from 'lodash/partial'

import { Uploadable } from 'simple-core-ui/components'

class UploadableContainer extends React.Component {
  static defaultProps = {
    paramsUrl: '/upload/params',
    acceptedTypes: '*',
    style: {}
  }

  state = {
    isUploading: false,
    isError: false
  }

  getUploadConfig = async file => {
    const { paramsUrl } = this.props

    try {
      const response = await axios.get(paramsUrl, {
        data: file.name
      })

      return response.data
    } catch (e) {
      throw e
    }
  }

  createFormData = (uploadConfig, file) => {
    const fd = new FormData()
    fd.append('key', uploadConfig.key)
    fd.append('AWSAccessKeyId', uploadConfig.AWSAccessKeyId)
    fd.append('acl', 'private')
    fd.append('policy', uploadConfig.policy)
    fd.append('signature', uploadConfig.signature)
    fd.append('x-amz-meta-name', file.name)
    fd.append('x-amz-meta-size', String(file.size))
    fd.append('x-amz-meta-type', file.type)
    fd.append('x-amz-meta-upload-source', document.domain)
    fd.append('Content-Type', file.type)
    fd.append('file', file)
    return fd
  }

  uploadFile = async (url, formData) => {
    try {
      await axios({
        method: 'post',
        url,
        processData: false,
        contentType: false,
        data: formData
      })
    } catch (e) {
      throw e
    }
  }

  processUpload = async file => {
    const uploadConfig = await this.getUploadConfig(file)
    const formData = this.createFormData(uploadConfig, file)
    await this.uploadFile(uploadConfig.AWS_S3_BUCKET_URL, formData)

    return {
      key: uploadConfig.key,
      name: file.name,
      size: file.size,
      type: file.type
    }
  }

  changeHandler = event => {
    const files = event.currentTarget.files

    if (!files.length) {
      return
    }

    const { uploadSuccess } = this.props
    this.setState({ isUploading: true })

    try {
      const pendingUploads = Array.from(files).map(file => this.processUpload(file))

      Promise.all(pendingUploads).then(uploads => {
        uploadSuccess(uploads)
        this.setState({ isUploading: false })
      })
    } catch (error) {
      this.setState({ isError: true, isUploading: false })
    }
  }

  render() {
    const { isUploading, isError } = this.state
    const { acceptedTypes, multiple, style, render } = this.props

    return (
      <Uploadable
        isUploading={this.state.isUploading}
        onChange={this.changeHandler}
        acceptedTypes={acceptedTypes}
        multiple={multiple}
        style={style}
        render={partial(render, isUploading, isError)}
      />
    )
  }
}

export default UploadableContainer
